<template>
    <div class="login">
        <div class="login-wrap">
            <video class="login-video"  autoplay muted loop>
                <source src="/image/login-bg.mp4">
            </video>
            <div class="ms-login">
                <div class="ms-logo-img">
                    <img src="/image/logo.svg" alt="">
                </div>
                <el-form :model="form" label-width="0px" class="ms-content" v-if="!isResetShow">
                    <h3 class="mb-20">{{ $t('data.login.forgetPassword') }}</h3>
                    <el-form-item prop="Email" style="margin-bottom: 5px;">
                        <el-input v-model="form.account" placeholder="Email">
                            <template>
                                <el-button icon="el-icon-message"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="login-btn mt-20">
                        <el-button type="primary" @click="submitForm()">{{ $t('data.public.confirm') }}</el-button>
                    </div>
                    <p class="login-tips" v-html="$t('data.login.contact')"></p>
                </el-form>

                <el-form :model="formReset" label-width="0px" class="ms-content" v-if="isResetShow">
                    <h3 class="mb-20">{{ $t('data.login.forgetPassword') }}</h3>
                    <el-form-item prop="Email" style="margin-bottom: 5px;">
                        <el-input class="mb-20" type="password" :placeholder="$t('data.login.password')" v-model="formReset.password">
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                        <el-input type="password" :placeholder="$t('data.login.password')" v-model="formReset.password_confirmation">
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="login-btn mt-20">
                        <el-button type="primary" @click="passwordReset()">{{ $t('data.public.confirm') }}</el-button>
                    </div>
                    <p class="login-tips" v-html="$t('data.login.contact')"></p>
                </el-form>

            </div>
        </div>
    </div>
</template>

<style scoped>
.login{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #224486;
    background-size: 100%;
}

.login-video{
    min-width: 100%;
    min-height: 100%;
}
.ms-logo-img{
    width: 100%;
    height: 100px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
.ms-logo-img img{
    max-height: 100px;
    width: auto;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    overflow: hidden;

}
.ms-login::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    -o-backdrop-filter: blur(20px);
    -ms-backdrop-filter: blur(20px); 
    -moz-backdrop-filter: blur(20px); 
    -webkit-backdrop-filter: blur(20px); 
}
.ms-content {
    padding: 30px 30px;
}
.ms-content h3{
    color: #fff;
}
.ms-content .el-checkbox{
    color: #fff;
}
.login-text p{
    color: #fff;
    font-size: 12px;
    margin: 15px 0px;
}
.login-text p a{
    color: #03cad5 !important;
}

.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    text-align: center;
}
</style>

<script>
import { ElMessage } from "element-plus";
import { passwordEmail,passwordReset } from '../../api/index'
export default {
    name: 'Forget',
    data(){
        return {
            form: {
                account: ''
            },
            formReset: {
                token: '',
                account: '',
                password: '',
                password_confirmation: ''
            },
            isResetShow: false,

        }
    },
    mounted(){
        if(this.$route.query.token && this.$route.query.account){
            this.isResetShow = true;
            this.formReset.token = this.$route.query.token;
            this.formReset.account = this.$route.query.account;
        }
    },
    methods: {
        submitForm(){
            let apiData = this.form;
            passwordEmail({apiData}).then( res => {
                ElMessage.success("success");
            })
        },
        passwordReset(){
            let apiData = this.formReset;
            passwordReset({apiData}).then( res => {
                ElMessage.success("success");
                window.location.href = "/login"
            })
        }
    }
};
</script>